import {gql} from 'apollo-boost'

const fragment = `{
    id
    ref_local
    ref_external
    final_amount
    actual_amount
    charged_amount
    type
    status
    message
    txn_phone
    txn_phone_operator
    customer{
        id
        first_name
    }
    company{id name}
    request{id}
    createdAt
}`

export const get = gql`
    query getTransactions($data: TransactionWhereInput){
        transactions(where: $data)${fragment}
    }
`

export const update = gql`
    mutation updateTransaction($where: TransactionWhereUniqueInput!, $data: TransactionUpdateInput!){
        updateTransaction(where: $where, data: $data)${fragment}
    }
`
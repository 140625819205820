<template>
	<data-table
		v-if="show"
		:getQuery="queries.get"
		:conditions="conditions"
		:headers="headers"
		icon="mdi-cash"
		result_name="transactions"
		title="transactions"
		subtitle="List of all transaction"
	>

	</data-table>
</template>
<script>
import DataTable from '../../../components/datatable'
export default {
	
	components: {DataTable},
	data(){
		return {
			conditions : {},
			user: this.$store.getters['auth/getUserData'],
			show: false,
			queries: require('../../../../legacy/unwanted_gql/transaction.gql'),
			headers: [
				{
					text: "Amount (GHS)",
					align: "center",
					value: "final_amount",
					sortable: false
				},
				{
					text: "Charges (GHS)",
					value: "charged_amount",
					align: "center",
					sortable: false
				},
				{ text: "Phone No.", value: "txn_phone", sortable: false },
				{ text: "Network operator", value: "txn_phone_operator", sortable: false },
				{
					text: "Date Of Transaction",
					align: "left",
					value: "createdAt",
					sortable: true
				}
			],
		}
	},
	created(){
		const company_id = this.user.company.id
		this.conditions = {
			company: {
				id: company_id
			}
		}
		this.show = true
	}
};
</script>